
// import Web3 from "web3";
import Blocknative from "bnc-sdk";
import sdkSetup from "@/config/sdk-setup";
import configuration from "@/config/configuration";

// const web3 = new Web3('wss://speedy-nodes-nyc.moralis.io/53e8cd5b6a4efc7a70a311cf/bsc/testnet/ws')

function startSDK(opKey, routerAddr, watchAddr, onError, handleTransactionEvent) {
    // console.log(configuration)
    // create options object
    // eslint-disable-next-line no-unused-vars
    const options = {
        dappId: process.env.VUE_APP_BLOCK_NATIVE_DAPPID,
        networkId: 56,
        ws: WebSocket,
        transactionHandlers: [
            (event) => {
                handleTransactionEvent(event)
            }
        ],
        onerror: onError
    }

    configuration[1]["id"] = routerAddr;
    // configuration[1]["name"] = opKey;

    let _terms = [
        {
            "contractCall.params.token": watchAddr
        },
        {
            "contractCall.params.tokenA": watchAddr
        },
        {
            "contractCall.params.tokenB": watchAddr
        }
    ];

    configuration[1]["filters"][1]["terms"] = _terms;

    console.log(configuration)

    const blockNative = new Blocknative(options);
    sdkSetup(blockNative, configuration).then();

    return blockNative;
}

export default startSDK

/*


// call with the address of the account that you would like to receive status updates for
const {
    emitter, // emitter object to listen for status updates
    details // initial account details which are useful for internal tracking: address
} = blocknative.account("0x0000")

// register a callback for a txPool event
emitter.on("txPool", transaction => {
    transaction;
    console.log("Transaction is pending ")
})

emitter.on('all', transaction => {
    console.log(transaction)
})

details;

emitter.off('txPool')
*/