<template>
  <div class="name-column">
    <div class="name-row">
      <div style="width: 30px"></div>
      <div class="container">
        <p class="tips">购买</p>
        <p v-if="cModel" class="tips">合约机地址：{{routerConfigItem ? routerConfigItem.diy : ""}}</p>
        <img v-if="cModel && routerConfigItem" :src="'https://api.qrserver.com/v1/create-qr-code/?data='+routerConfigItem.diy" width="100px" height="100px">
        <div class="name-row">
          <p class="tips">平台：</p>
          <el-select v-model="routerConfigItem" value-key="id" placeholder="请选择" style="flex: 1">
            <div class="name-column">
              <el-option
                  v-for="item in routerConfig"
                  :key="item.id"
                  :label="item.name"
                  :value="item">
              </el-option>
            </div>
          </el-select>
        </div>
        <div style="height: 30px"></div>
        <div v-if=" routerConfigItem && routerConfigItem.id == 0" class="name-row">
          <el-input v-model="diyRouter" placeholder="请输入自定义路由地址">
          </el-input>

        </div>
        <div style="height: 30px"></div>
        <el-input v-model="sellRpc" placeholder="ws节点rpc" style="width: 100%; text-align: center">
          <template slot="prepend">节点RPC:</template>
        </el-input>
        <div class="name-row">
          <div style="text-align: left">
            <div style="height: 20px"/>
            <el-input v-model="gasPrice" placeholder="手续费" style="width: 200px; text-align: center">
              <template slot="prepend">Gas Price:</template>
            </el-input>
          </div>
          <div style="width: 20px;"/>
          <div style="text-align: left">
            <div style="height: 20px"/>
            <el-input v-model="gasLimit" placeholder="手续费" style="width: 300px; text-align: center">
              <template slot="prepend">Gas Limit:</template>
            </el-input>
          </div>
        </div>
        <div style="height: 30px"></div>

        <div class="name-row name-row-topLeft">
          <div style="width: 140px">
            <el-switch
                v-model="txModel"
                active-text="监测"
                inactive-text="轮询">
            </el-switch>
          </div>
          <div style="width: 30px"/>
          <div class="name-column name-column-centerLeft" style="width: 100px; font-size: 14px; color: #999999">
            <el-switch
                v-model="robModel"
                inactive-text="抢链：">
            </el-switch>
            烧Gas
          </div>
          <div style="width: 30px"/>
          <div class="name-column name-column-centerLeft" style="width: 200px; font-size: 14px; color: #999999">
            <el-switch
                v-model="autoRetry"
                inactive-text="自动重试：">
            </el-switch>
            当遇到白名单、时间锁的代币合约，请开启此选项!会自动嗅探检测能够购买再进行买入，不会上链和消耗手续费!
          </div>
          <div style="width: 30px"/>
          <div class="name-column name-column-centerLeft" style="width: 200px; font-size: 14px; color: #999999">
            <el-input v-model="oneTime" style="width: 200px" placeholder="嗅探频率：">
              <template slot="prepend">嗅探频率:</template>
            </el-input>
            毫秒秒为单位，越小越快。根据RPC节点合理设置。（1000毫秒=1秒，比如设置为500就是1秒嗅探2次）
          </div>
        </div>
        <div class="block">
          <div style="height: 20px"/>
          <div class="name-row name-row-centerLeft">
            <div style="width: 100px; text-align: left">滑点：</div>
            <div class="name-flex-value">
              <el-slider
                  v-model="slippage"
                  show-input :step="5" show-stops>
              </el-slider>
            </div>
            <div>
              <div class="name-column name-column-centerLeft" style="width: 260px; font-size: 14px; color: #999999; padding-left: 20px">
                <el-switch
                    v-model="failRetry"
                    inactive-text="失败重试：">
                </el-switch>
                购买失败，会重新发起购买请求，直到购买成功或者你关闭抢购窗口。
                部分能够发起购买请求但购买失败的代币，请打开这个选项
              </div>
            </div>
          </div>
        </div>
        <div style="height: 30px"/>

        <el-input v-model="privateKey" placeholder="私钥地址">
          <template slot="prepend">私钥:</template>
          <el-button @click="privateToAccount" slot="append" type="primary">导入</el-button>
        </el-input>

        <div v-if="address" class="name-row">
          <div class="name-flex-value config-item">
            <p class="tips">钱包地址:</p>
            <p class="bg-color">{{address}}</p>
          </div>
          <div class="name-flex-value config-item">
            <p class="tips">余额:</p>
            <p class="bg-color">{{ ethValue }}</p>
          </div>
        </div>
        <div style="height: 10px"></div>
        <div class="name-row">
          <el-input v-model="buyNum" placeholder="支付数量：">
            <template slot="prepend">支付数量:</template>
          </el-input>
          <el-input v-model="poolNum" placeholder="池子规模：">
            <template slot="prepend">池子规模:</template>
          </el-input>
        </div>
        <div style="height: 10px"></div>
        <div class="name-row">
          <el-input v-model="inMin" placeholder="支付数量：">
            <template slot="prepend">最小购买数量:</template>
          </el-input>
<!--          <el-input v-model="inMax" placeholder="指定购买数量：">-->
<!--            <template slot="prepend">指定购买数量:</template>-->
<!--          </el-input>-->
        </div>

        <div style="height: 10px"></div>

        <div class="name-row">
          <div>
            <p>一单多买</p>
            <el-input v-model="multiInMax" placeholder="指定获得数量：">
              <template slot="prepend">指定获得数量:</template>
            </el-input>
          </div>
          <div>
            <p>延迟购买（1 = 延迟1秒，0无延迟）</p>
            <el-input v-model="delayBlock" placeholder="延迟区块：">
              <template slot="prepend">延迟购买:</template>
            </el-input>
          </div>
        </div>

        <div style="height: 30px"/>
        <p class="tips">发送Token地址:
          <span> 名字：[{{baseTokenConfig.tokenSymbol}}]
            decimal:[{{baseTokenConfig.decimals}}]
            [{{baseTokenConfig.approved > 0 ? "已授权" : "未授权"}}]
          </span>
        </p>
        <p>
          钱包：<span style="color: red">{{fixAmount(baseTokenConfig.balance, baseTokenConfig.fixDecimals)}}</span>
          <span v-if="cModel"> 合约机：</span> <span v-if="cModel" style="color: red">{{fixAmount(baseTokenConfig.quickBalance, baseTokenConfig.fixDecimals)}}</span>
          <el-button v-if="cModel" style="margin-left: 10px" @click="withdraw(1)" type="primary">{{"提取"}}</el-button>
<!--          <el-button @click="deposit(1)" type="primary">{{"存入"}}</el-button>-->
        </p>
        <div class="name-row">
          <el-input v-model="tokenSender" placeholder="请输入token的合约地址">
          </el-input>
          <el-button @click="checkTokenInfo(1, true)" slot="append" type="primary">检测</el-button>
          <el-button @click="approveToken(1)" slot="append" type="primary">提前授权</el-button>
        </div>

        <p class="tips">获得Token地址:
          <span> 名字：[{{ quoteTokenConfig.tokenSymbol }}]
            decimal:[{{quoteTokenConfig.decimals}}]
            [{{quoteTokenConfig.approved > 0 ? "已授权" : "未授权"}}]
          </span>
        </p>
        <p>
          钱包：<span style="color: red">{{fixAmount(quoteTokenConfig.balance, baseTokenConfig.fixDecimals)}}</span>
          <span v-if="cModel"> 合约机：</span> <span v-if="cModel" style="color: red">{{fixAmount(quoteTokenConfig.quickBalance, baseTokenConfig.fixDecimals)}}</span>

          <el-button v-if="cModel" style="margin-left: 10px" @click="withdraw(2)" type="primary">{{"提取"}}</el-button>
<!--          <el-button @click="deposit(2)" type="primary">{{"存入"}}</el-button>-->
        </p>
        <div class="name-row">
          <el-input v-model="tokenRecipient" placeholder="请输入获得token的合约地址">
          </el-input>
          <el-button @click="checkTokenInfo(2, true)" slot="append" type="primary">检测</el-button>
          <el-button @click="approveToken(2)" slot="append" type="primary">提前授权</el-button>
        </div>

        <div style="height: 30px"/>
        <div class="name-row">
          <div style="width: 100px">钱包地址:</div>
          <el-input type="textarea" v-model="recipient" placeholder="请输入接收代币的钱包地址">

          </el-input>
        </div>

        <div style="height: 30px"/>
        <div class="name-row name-flex-center">
          <el-button @click="start" type="primary">{{run ? "结束" : "开始！！！"}}</el-button>
        </div>

      </div>
      <div style="width: 30px"></div>
      <div class="container">
        <p class="tips" style="color: red">卖出</p>

        <div style="margin-left: 10px">
          <div v-if="cModel" style="text-align: left">
            <el-switch
                v-model="sellIsWallet"
                inactive-text="从合约机卖出"
                active-text="从钱包卖出">
            </el-switch>
            <span>（仅支持当前私钥钱包和合约机卖出）</span>
          </div>
          <div style="height: 20px"/>


          <div class="name-row name-row-centerLeft">
            <p style="text-align: left">可卖出 <span style="color: red">{{sellGetMax}}</span> {{baseTokenConfig.tokenSymbol}}</p>
          </div>
          <el-button @click="sell(3)" type="primary" style="width: 100px; height: 40px">卖出1/3</el-button>
          <el-button @click="sell(2)" type="primary" style="width: 100px; height: 40px">卖出1/2</el-button>
          <el-button @click="sell(1)" type="primary" style="width: 100px; height: 40px">全部卖出</el-button>
          <div style="height: 10px"/>
          <div style="height: 600px; background-color: rgba(238, 255, 243, 1)">
            <div v-html="logMsg" style="
            width: 100%;
            height: 100%;
            color: white;
            padding-left: 10px;
            font-size: 16px;
            outline: none;
            text-align: left;
            overflow: auto;
            background-color: transparent;"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Web3 from "web3";
import startSDK from "@/js/utils";
import routerConfig from "@/config/router-config";
import routerAbi from "@/assets/abi/routerAbi";
import quickSwapAbi from "@/assets/abi/quickSwapAbi";
import IERC20MetadataABI from "@/assets/abi/IERC20MetadataABI";
import BigNumber from "bignumber.js";
import AbiDecoder from "abi-decoder";
import {ethers} from "ethers";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      cModel: false,
      logMsg: "",
      max: "115792089237316195423570985008687907853269984665640564039457584007913129639935",
      web3: null,
      tokenSender: "",
      tokenRecipient: "",
      txModel: false,
      robModel: false,
      autoRetry: false,
      failRetry: false,
      slippage: 0,
      oneTime: 500,
      timer: null,
      autoTimer: null,
      failTimer: null,
      sellGetMax: 0,

      listening: false,
      subscription: null,
      routerConfig: [],
      routerConfigItem: null,
      diyRouter: null,
      routerContract: null,
      privateKey: "",
      quickContract: null,

      address: null,
      ethValue: 0,
      recipient: null,

      baseTokenConfig: {
        token: null,//"0x0dE8FCAE8421fc79B29adE9ffF97854a424Cad09", //
        decimals: 0,
        fixDecimals: 0,
        balance: 0,
        quickBalance: 0,
        approved: 0,
      },
      quoteTokenConfig: {
        token: null,//"0xE3aAD9Ba62eD996d3daa85E7B1aDbc3F2d3559bc", //
        decimals: 0,
        fixDecimals: 0,
        balance: 0,
        quickBalance: 0,
        approved: 0,
      },

      run: false,

      buyNum: 0,
      poolNum: 0,
      inMin: 0,
      inMax: 0,
      multiInMax: 0,
      delayBlock: 0,
      gasPrice: 10,
      gasLimit: 2000000,


      buyNumFix: "0",
      poolNumFix: "0",
      inMinFix: "0",
      inMaxFix: "0",
      multiInMaxFix :"0",
      gasPriceFix: "10000000000",
      router: "0x",
      watchMethods: {addLiquidityETH: true, addLiquidity: true},
      watchAddress: "",

      connecting: false,
      provider: null,
      sellIsWallet: true,
      blockNative: null,
      nonce: 0,
      sellRpc: "https://bsc-dataseed2.binance.org/",
    }
  },
  computed: {
    qr() {
      if (!this.routerConfigItem) {
        return ""
      }
      let qrUrl = "https://api.qrserver.com/v1/create-qr-code/?data="+this.routerConfigItem.diy;
      console.log(qrUrl)
      return qrUrl;
    }
  },
  watch: {
    sellRpc: function (value) {
      localStorage.setItem("ws-rpc", value);
    },
    buyNum: function (value) {
      this.buyNumFix = this.fixToContract(value, this.baseTokenConfig.fixDecimals);
    },
    poolNum: function (value) {
      this.poolNumFix = this.fixToContract(value, this.baseTokenConfig.fixDecimals);
    },
    inMin: function (value) {
      this.inMinFix = this.fixToContract(value, this.baseTokenConfig.fixDecimals);
    },
    inMax: function (value) {
      this.inMaxFix = this.fixToContract(value, this.baseTokenConfig.fixDecimals);
    },
    multiInMax: function (value) {
      this.multiInMaxFix = this.fixToContract(value, this.baseTokenConfig.fixDecimals);
    },
    baseTokenConfig: function (_config) {
      this.buyNumFix = this.fixToContract(this.buyNum, _config.fixDecimals);
      this.poolNumFix = this.fixToContract(this.poolNum, _config.fixDecimals);
      this.inMinFix = this.fixToContract(this.inMin, _config.fixDecimals);
      this.inMaxFix = this.fixToContract(this.inMax, _config.fixDecimals);
      this.multiInMaxFix = this.fixToContract(this.multiInMax, _config.fixDecimals);
    }
  },
  mounted() {

    console.log(process.env.NODE_ENV)
    this.cModel = process.env.VUE_APP_MODEL == "MAIN";
    let sellRpc = localStorage.getItem("ws-rpc");
    if (sellRpc != null && sellRpc != "" && sellRpc != "null") {
      console.log(`sellRpc = ${sellRpc}`)
      this.sellRpc = sellRpc;
    }
    // this.web3 = new Web3("wss://speedy-nodes-nyc.moralis.io/53e8cd5b6a4efc7a70a311cf/bsc/mainnet/ws");
    this.initWeb3();
    // this.web3 = new Web3("https://speedy-nodes-nyc.moralis.io/53e8cd5b6a4efc7a70a311cf/bsc/mainnet");
    // this.web3 = new Web3("ws://usa2.fastnode.cyou:8546");
    // this.web3 = new Web3(Web3.givenProvider);
    // console.log(this.web3.defaultAccount)
    this.routerConfig = routerConfig;
    this.routerConfigItem = routerConfig[0];

    // let web3 = this.web3;
    // let account = web3.eth.accounts.privateKeyToAccount(this.privateKey);
    // web3.eth.accounts.wallet.add(this.privateKey);
    // web3.eth.defaultAccount = account.address;

    this.checkTokenInfos();

// unsubscribes the subscription

  },
  methods: {
    initWeb3() {
      try {
        let self = this;
        if (!self.provider && self.sellRpc) {
          let options = {
            timeout: 30000,
            clientConfig: {
              // Useful if requests are large
              maxReceivedFrameSize: 100000000,   // bytes - default: 1MiB
              maxReceivedMessageSize: 100000000, // bytes - default: 8MiB

              // Useful to keep a connection alive
              keepalive: true,
              keepaliveInterval: 60000 // ms
            },
            reconnect: {
              auto: true,
              delay: 1000,
              maxAttempts: 5,
              ontimeout: false
            }
          }
          if (self.sellRpc.startsWith("ws")) {
            let provider = new Web3.providers.WebsocketProvider(self.sellRpc, options);
            self.provider = provider;
            self.provider.on("end", e=>{
              console.log('WS end', e)
              // self.web3 = new Web3(self.provider);
              setTimeout(()=>{
                // self.startListen();
                provider.reconnect();
              }, 1000);
            })
            self.provider.on('error', e => {
                console.log('WS error', e)
                // self.web3 = new Web3(self.provider);
                setTimeout(()=>{
                  // self.startListen();
                  provider.reconnect();
                }, 1000);
              });
            } else {
              let provider = new Web3.providers.HttpProvider(self.sellRpc);
              self.provider = provider;
            }
          }


        if (self.provider) {
          self.web3 = new Web3(self.provider);
        }
      } catch (e) {
        console.log("web3 init fail")
      }
    },
    getDataByHash(transactionHash, callBack) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async resolve => {
        try {
          let web3 = this.web3;
          let result = await web3.eth.getTransaction(transactionHash);
          if (result) {
            console.log("result")
            console.log(result)
            callBack?.call(result);
            resolve(result);
          } else {
            setTimeout(async () => {
              let result = await web3.eth.getTransaction(transactionHash);
              callBack?.call(result);
              resolve(result);
            },10);
          }
        } catch (e) {
          resolve(null);
        }

      });
    },
    fixToContract(amount, fixDecimals) {
      try {
        let valueBN = new BigNumber(amount);
        let resultBN = valueBN.times(fixDecimals).toFixed(0);
        return resultBN;
      } catch (e) {
        return "0";
      }
    },
    fixAmount(amount, fixDecimals) {
      if (!amount || !fixDecimals) {
        return 0;
      }
      try {
        let amountBN = new BigNumber(amount);
        let balance = amountBN.div(fixDecimals).times(10000).toFixed(0);
        return parseInt(balance)/10000;
      } catch (e) {
        return 0;
      }
    },
    initContract() {
      if (!this.sellRpc) return;
      if (!this.web3) {
        this.initWeb3();
      }
      if (!this.web3) {
        return;
      }
      let web3 = this.web3;
      let router = this.router;
      let quickAddr = this.routerConfigItem.diy;
      this.routerContract = new web3.eth.Contract(routerAbi, router);
      this.quickContract = new web3.eth.Contract(quickSwapAbi, quickAddr);
    },
    startBlockNative(opKey) {
      let router = this.router;
      let quickAddr = this.routerConfigItem.diy;
      if (!this.routerContract || !this.quickContract) {
        this.initContract();
      }
      console.log("this.routerConfigItem.diy = %s", quickAddr)
      let watchAddr = this.tokenRecipient;
      if (!watchAddr) {
        console.log("请输入接受Token")
        this.$message.error("请输入接受Token")
        this.insertErrorLogMsg("请输入接受Token")
        this.run = false;
        return;
      }
      if (!this.tokenSender) {
        console.log("请输入发送Token")
        this.$message.error("请输入发送Token")
        this.insertErrorLogMsg("请输入发送Token")
        this.run = false;
        return;
      }
      let self = this;
      self.blockNative = startSDK(opKey, router, watchAddr,(err)=>{
        console.log(err)
      }, (event) => {
        if (!self.run) {
          return;
        }
        this.handleTransactionEvent(event);
        // self.blockNative.unsubscribe(watchAddr);
        // self.blockNative.unsubscribe(router);
        self.blockNative.destroy();
        self.run = false;
      });
    },
    startMoralisListen() {
      let web3 = this.web3;
      let self = this;
      if (this.listening) {
        // 取消订阅
        this.subscription.unsubscribe(function (error, success) {
          if (success)
            self.listening = false;
          console.log('Successfully unsubscribed!');
        });
        return;
      }
      this.listening = true;

      this.subscription = web3.eth.subscribe('pendingTransactions', function (error, result) {
        if (!error) {
          console.log("result");
          console.log(result);
        } else {
          console.log("err");
          console.log(error);
        }

      })
          .on("data", function (transaction) {
            console.log("data");
            console.log(transaction);
          });
    },
    async startListen() {
      // let web3 = this.web3;
      // let self = this;
      AbiDecoder.addABI(routerAbi);

      let router = this.router;
      let quickAddr = this.routerConfigItem.diy;
      if (!this.routerContract || !this.quickContract) {
        this.initContract();
      }
      console.log("this.routerConfigItem.diy = %s", quickAddr)
      let watchAddr = this.tokenRecipient;

      let watchRouter = {};
      watchRouter[router] = true;

      let watchToken = {};
      watchToken[watchAddr] = true;

      const provider = new ethers.providers.WebSocketProvider("wss://speedy-nodes-nyc.moralis.io/53e8cd5b6a4efc7a70a311cf/bsc/mainnet/ws")
      console.log(provider._websocket)

    },
    handleTransactionEvent(event) {
      let self = this;
      self.insertNormalLogMsg(`监听到加池子数据`)
      if (this.delayBlock > 0) {
        self.insertNormalLogMsg(`延迟${this.delayBlock}秒购买`)
        setTimeout(()=>{
          self.sendRequest(event);
        }, this.delayBlock*1000);
       return;
      }
      self.insertNormalLogMsg(`不延迟购买`)

      this.sendRequest(event);
    },
    async sendRequest(event) {
      console.log("event")
      console.log(event)
      let self = this;

      let transaction = event.transaction;
      let gasPrice = transaction.gasPrice;
      let contractCall = transaction.contractCall;
      let params = contractCall.params;
      let deadline = params.deadline;
      let _tokenA = params.tokenA;
      let _tokenB = params.tokenB;
      let _amountA = params.amountADesired;
      let _amountB = params.amountBDesired;
      let router = this.router;
      console.log("gasPrice = %s deadline = %s, _tokenA = %s, _tokenB = %s",gasPrice,deadline,_tokenA,_tokenB)

      self.run = true;
      try {
        let from = self.web3.eth.defaultAccount;
        if (contractCall.methodName === "addLiquidityETH") {
          if (self.poolNum > 0) {
            let poolNumBN = new BigNumber(this.poolNumFix);
            let valueBN = new BigNumber(transaction.value+"");
            if (self.baseTokenConfig.tokenSymbol !== "WBNB") {
             valueBN = valueBN.times(400);
            }
            if (poolNumBN.gt(valueBN)) {
              console.log("不满足池子要求")
              self.insertErrorLogMsg("不满足池子要求未购买")
              return;
            }
          }

          /// 购买指定数量
          if (self.inMax > 0) {
            await self.quickMultiSwapForAmountInMax(
                router,
                self.tokenRecipient,
                self.tokenSender,
                self.tokenRecipient,
                gasPrice,
                from
            );
            return;
          }
          /// 一单多买
          if (self.multiInMax > 0) {
            await self.quickMultiSwapForAmountInMaxs(
                router,
                self.tokenRecipient,
                self.tokenSender,
                self.tokenRecipient,
                gasPrice,
                from
            );
            return;
          }


          await self.quickSwap(
              router,
              self.tokenRecipient,
              self.tokenSender,
              self.tokenRecipient,
              gasPrice,
              from
          );
        } else if (contractCall.methodName === "addLiquidity") {
          let watchAddr = self.tokenRecipient;
          if (self.poolNum > 0) {
            let poolNumBN = new BigNumber(this.poolNumFix);

            if (self.baseTokenConfig.tokenSymbol === "WBNB") {
              poolNumBN = poolNumBN.times(400);
            }
            if (watchAddr === _tokenA && poolNumBN.gt(_amountA+"")
                || watchAddr === _tokenB && poolNumBN.gt(_amountB+"")) {
              console.log("不满足" + this.poolNumFix + "池子要求")
              self.insertErrorLogMsg("不满足池子要求未购买")
              return;
            }
          }

          /// 购买指定数量
          if (self.inMax > 0) {
            await self.quickMultiSwapForAmountInMax(router, watchAddr, _tokenA, _tokenB, gasPrice, from)
            return;
          }
          /// 一单多买
          if (self.multiInMax > 0) {
            await self.quickMultiSwapForAmountInMaxs(router, watchAddr, _tokenA, _tokenB, gasPrice, from)
            return;
          }

          await self.quickSwap(
              router,
              watchAddr,
              _tokenA,
              _tokenB,
              gasPrice,
              from
          );
        }
      } catch (e) {
        console.log("err-sendRequest")
        console.log(e)
      }
    },
    async quickSwap(router, watchAddr, _tokenA, _tokenB, gasPrice, from, emitGas) {
      let self = this;
      if (!self.run) {
        return;
      }

      let showErr = true;
      try {
        let recs = this.recipient.split("\n");
        let rec = recs[0];
        if (rec.length == 0) {
          self.insertErrorLogMsg("钱包接收地址有误，请检查")
          return;
        }

        if (emitGas) {
          showErr = false;
          await self.quickContract.methods.quickSwap(
              router,
              watchAddr,
              self.tokenSender,
              _tokenA,
              _tokenB,
              self.buyNumFix,//发送多少代币（用多少代币购买）
              self.inMinFix,//最少接收多少代币
              rec,
              self.slippage
          ).estimateGas({gas: self.gasLimit});
          showErr = true;
          self.insertSuccessLogMsg("模拟购买成功")
          return true;
        }
        if (!self.run) {
          return false;
        }
        self.insertNormalLogMsg("开始购买->Normal")
        let sendMap = {
          from: from,
          gasPrice: gasPrice,
          gasLimit: self.gasLimit
        }
        if (self.robModel) {
          sendMap["nonce"] = self.nonce;
        }
        let result = await self.quickContract.methods.quickSwap(
            router,
            watchAddr,
            self.tokenSender,
            _tokenA,
            _tokenB,
            self.buyNumFix,//发送多少代币（用多少代币购买）
            self.inMinFix,//最少接收多少代币
            rec,
            self.slippage
        ).send(sendMap).once("transactionHash", (tx) => {
          self.insertSuccessLogMsg("购买已提交,hash:"+tx)
          self.nonce = self.nonce+1;
        });
        if (result.status == true) {

          self.$message.success("购买成功")
          self.insertSuccessLogMsg("购买成功")
          self.run = false;
        } else {
          self.$message.error("购买失败")
          self.insertErrorLogMsg("购买失败")
          if (self.failRetry) {
            if (self.failTimer) {
              clearTimeout(self.failTimer);
            }
            self.failTimer = null;
            self.failTimer = setTimeout(()=>{
              if (!self.failTimer) return;
              clearTimeout(self.failTimer);
              self.failTimer = null;
              self.quickSwap(router, watchAddr, _tokenA, _tokenB, gasPrice, from, emitGas);
            }, self.oneTime);
          } else {
            self.run = false;
          }
        }
        return result.status;
      } catch (e) {
        if (showErr) {
          self.insertErrorLogMsg("购买失败"+e.message.split("(")[0])
          if (self.failRetry && self.run) {
            if (self.failTimer) {
              clearTimeout(self.failTimer);
            }
            self.failTimer = null;
            self.failTimer = setTimeout(()=>{
              if (!self.failTimer) return;
              clearTimeout(self.failTimer);
              self.failTimer = null;
              self.quickSwap(router, watchAddr, _tokenA, _tokenB, gasPrice, from, emitGas);
            }, self.oneTime);
          } else {
            self.run = false;
          }
        } else {
          self.insertErrorLogMsg("模拟购买失败"+e.message.split("(")[0])
          if (emitGas) {
            return false;
          }
        }

      }
    },
    async quickMultiSwapForAmountInMax(router, watchAddr, _tokenA, _tokenB, gasPrice, from) {
      try {
        let self = this;
        self.insertNormalLogMsg("开始购买->Max")
        let recs = this.recipient.split("\n");
        let rec = recs[0];
        if (rec.length == 0) {
          self.insertErrorLogMsg("钱包接收地址有误，请检查")
          return;
        }
        let result = await self.quickContract.methods.quickMultiSwapForAmountInMax(
            router,
            watchAddr,
            self.tokenSender,
            _tokenA,
            _tokenB,
            self.inMaxFix,//购买固定数量的代币
            self.buyNumFix,//发送多少代币（最多用多少代币购买）
            rec,
            self.slippage
        ).send({
          from: from,
          gasPrice: gasPrice,
          gasLimit: 2000000,
        }).once("transactionHash", (tx) => {
          self.insertSuccessLogMsg("购买已提交,hash:"+tx)
        });
        if (result.status == true) {

          self.$message.success("购买成功")
          self.insertSuccessLogMsg("购买成功")
        } else {
          self.$message.error("购买失败")
          self.insertErrorLogMsg("购买失败")
        }
        return result.status;
      } catch (e) {
        self.insertErrorLogMsg("购买失败"+e.message.split("(")[0])
      }
    },
    async quickMultiSwapForAmountInMaxs(router, watchAddr, _tokenA, _tokenB, gasPrice, from, emitGas) {
      let self = this;

      let showErr = true;
      try {
        let recs = this.recipient.split("\n");
        let realRecs = [];
        for (let i = 0; i < recs.length; i++) {
          let rec = recs[i];
          if (rec.length == 0) {
            continue;
          }
          realRecs.push(rec);
        }
        if (emitGas) {
          showErr = false;
          await self.quickContract.methods.quickMultiSwapForAmountInMaxs(
              router,
              watchAddr,
              self.tokenSender,
              _tokenA,
              _tokenB,
              self.multiInMaxFix,//购买固定数量的代币
              self.buyNumFix,//发送多少代币（最多用多少代币购买）
              realRecs,
              self.slippage
          ).estimateGas({gas: self.gasLimit});
          self.insertSuccessLogMsg("模拟购买成功")
          showErr = true;
          return true;
        }
        if (!self.run) return false;
        let sendMap = {
          from: from,
          gasPrice: gasPrice,
          gasLimit: self.gasLimit
        }
        if (self.robModel) {
          sendMap["nonce"] = self.nonce;
        }
        self.insertNormalLogMsg("开始购买->Maxs")
        let result = await self.quickContract.methods.quickMultiSwapForAmountInMaxs(
            router,
            watchAddr,
            self.tokenSender,
            _tokenA,
            _tokenB,
            self.multiInMaxFix,//购买固定数量的代币
            self.buyNumFix,//发送多少代币（最多用多少代币购买）
            realRecs,
            self.slippage
        ).send(sendMap).once("transactionHash", (tx) => {
          self.insertSuccessLogMsg("购买已提交,hash:"+tx)
          self.nonce = self.nonce+1;
        });
        if (result.status == true) {

          self.$message.success("购买成功")
          self.insertSuccessLogMsg("购买成功")
        } else {
          self.$message.error("购买失败")
          self.insertErrorLogMsg("购买失败")
          if (self.failRetry) {
            if (self.failTimer) {
              clearTimeout(self.failTimer);
            }
            self.failTimer = null;
            self.failTimer = setTimeout(()=>{
              if (!self.failTimer) return;
              clearTimeout(self.failTimer);
              self.failTimer = null;
              self.quickMultiSwapForAmountInMaxs(router, watchAddr, _tokenA, _tokenB, gasPrice, from, emitGas);
            }, self.oneTime);
          }
        }
        return result.status;
      } catch (e) {
        if (showErr) {
          self.insertErrorLogMsg("购买失败"+e.message.split("(")[0])
          if (self.failRetry) {
            if (self.failTimer) {
              clearTimeout(self.failTimer);
            }
            self.failTimer = null;
            self.failTimer = setTimeout(()=>{
              if (!self.failTimer) return;
              clearTimeout(self.failTimer);
              self.failTimer = null;
              self.quickMultiSwapForAmountInMaxs(router, watchAddr, _tokenA, _tokenB, gasPrice, from, emitGas);
            }, self.oneTime);
          }
        } else {
          self.insertErrorLogMsg("模拟购买失败"+e.message.split("(")[0])
          if (emitGas) {
            return false;
          }
        }

      }
    },
    async privateToAccount() {
      try {
        console.log("privateToAccount")
        console.log(`this.web3 = ${this.web3}`)
        if (!this.web3) {
          console.log(`初始化web3 = ${this.web3}`)
          this.initWeb3();
        }
        let web3 = this.web3;
        console.log(web3)
        let pks = this.privateKey.split("\n");
        console.log(pks)
        let fistBalance = false;
        for (let i = 0; i < pks.length; i++) {
          let pk = pks[i];
          if (pk.length == 0) {
            continue
          }
          let account = web3.eth.accounts.privateKeyToAccount(pk);
          web3.eth.accounts.wallet.add(pk);
          if (!fistBalance) {
            web3.eth.defaultAccount = account.address;
            this.address = account.address;
            let ethValue = await web3.eth.getBalance(account.address);
            this.ethValue = web3.utils.fromWei(ethValue, "ether")
            fistBalance = true
          }
        }
      } catch (e) {
        console.log(e)
        this.$message.error("私钥倒入失败")
      }


    },
    async checkTokenInfo(index, _alert) {
      try {
        let self = this;
        let web3 = self.web3;
        if (!self.address) {
          await self.privateToAccount();
        }
        let router = self.routerConfigItem.id != 0 ? self.routerConfigItem.id : self.diyRouter;
        self.router = router;

        if (!self.routerContract || !self.quickContract) {
          self.initContract();
        }
        if (!self.web3) {
          return;
        }
        // let tokenContract = await new web3.eth.Contract(IERC20MetadataABI, index==1 ? self.baseTokenConfig.token : self.quoteTokenConfig.token);
        let tokenContract = self.quickContract;
        let token = index==1 ? self.tokenSender : self.tokenRecipient;
        if (!token || token.length == 0) {
          return;
        }

        let tokenInfo = await tokenContract.methods.tokenInfo(
            token,
            router
        ).call({
          from: web3.eth.defaultAccount
        });
        if (index == 1) {
          self.baseTokenConfig = tokenInfo;
        } else {
          self.quoteTokenConfig = tokenInfo;
        }

        if (_alert) {
          self.$message.success("检测成功，" + tokenInfo.tokenSymbol + " decimals: " + tokenInfo.decimals);
        }

      } catch (e) {
        if (_alert) {
          self.$message.error("检测失败"+e)
        }

      }
    },
    async approveToken(index) {
      try {
        let self = this;

        let web3 = self.web3;
        let router = self.routerConfigItem.id != 0 ? self.routerConfigItem.id : self.diyRouter;

        /// 提前定义router
        this.router = router;

        let token = index==1 ? self.baseTokenConfig.token : self.quoteTokenConfig.token;
        if (index == 1 && self.baseTokenConfig.approved > 0) {
          self.$message.success("授权过了");
          return;
        } else if (self.quoteTokenConfig.approved > 0) {
          self.$message.success("授权过了");
          return;
        }
        console.log(`token = ${token} router = ${router}`)
        self.insertNormalLogMsg("开始授权")
        let tokenContract = await new web3.eth.Contract(IERC20MetadataABI, token);
        let result = await tokenContract.methods.approve(router, self.max).send({
          from: web3.eth.defaultAccount,
          gasPrice: web3.utils.toWei("7", "gwei"),
          gasLimit: 1000000,
        }).once("transactionHash", (tx) => {
          self.insertSuccessLogMsg("授权已提交,hash:"+tx)
        });
        if (result.status == true) {
          self.insertSuccessLogMsg("授权成功")
          self.$message.success("授权成功");
        }
        this.checkTokenInfo(index).then();
      } catch (e) {
        this.$message.error("授权失败"+e);
        this.insertErrorLogMsg("授权失败")
      }
    },
    start() {
      if (!this.txModel) {
        this.startTxListen();
        return;
      }
      if (this.run) {
        this.run = false;
        this.insertNormalLogMsg("结束监听")
        let router = this.router;
        let watchAddr = this.tokenRecipient;
        self.blockNative.unsubscribe(watchAddr);
        self.blockNative.unsubscribe(router);
        return;
      }
      if (!this.privateKey) {
        this.$message.error("请输入私钥地址")
        return;
      }
      if (!this.buyNum||this.buyNum <= 0) {
        this.$message.error("请输入购买金额")
        return;
      }
      if (!this.tokenSender) {
        this.$message.error("请输入发送token")
        return;
      }
      if (!this.tokenRecipient) {
        this.$message.error("请输入接收token")
        return;
      }
      if (!this.recipient) {
        this.$message.error("请输入钱包地址")
        return;
      }

      if (!this.sellRpc) {
        this.$message.error("请输入节点 RCP")
        return;
      }

      this.resetLogMsg("开始监听");
      this.run = true;
      console.log("this.buyNum = %s, this.buyNumFix = %s", this.buyNum, this.buyNumFix)
      console.log("this.poolNum = %s, this.poolNumFix = %s", this.poolNum, this.poolNumFix)
      console.log("this.inMin = %s, this.inMinFix = %s", this.inMin, this.inMinFix)
      console.log("this.inMax = %s, this.inMaxFix = %s", this.inMax, this.inMaxFix)
      console.log("this.multiInMax = %s, this.multiInMaxFix = %s", this.multiInMax, this.multiInMaxFix)
      console.log("this.delayBlock = %s", this.delayBlock)

      // return;
      let privateKeys = this.privateKey.split("\n")
      for (let i = 0; i < privateKeys.length; i++) {
        let opKey = privateKeys[i];
        if (opKey.replace(" ", "").length == 0) {
          continue;
        }
        if (opKey) {
          this.startBlockNative(opKey);
          return;
        }
      }
    },
    async startTxListen() {
      let self = this;
      if (this.run) {
        this.run = false;
        if (self.timer) {
          clearInterval(self.timer);
        }
        if (self.autoTimer) {
          clearInterval(self.autoTimer);
        }
        self.timer = null;
        self.autoTimer = null;

        if (self.failTimer) {
          clearTimeout(self.failTimer);
        }
        self.failTimer = null;
        return;
      }
      if (!this.privateKey) {
        this.$message.error("请输入私钥地址")
        return;
      }
      if (!this.buyNum||this.buyNum <= 0) {
        this.$message.error("请输入购买金额")
        return;
      }
      if (!this.tokenSender) {
        this.$message.error("请输入发送token")
        return;
      }
      if (!this.tokenRecipient) {
        this.$message.error("请输入接收token")
        return;
      }
      if (!this.recipient) {
        this.$message.error("请输入钱包地址")
        return;
      }

      if (!this.sellRpc) {
        this.$message.error("请输入节点 RCP")
        return;
      }

      this.resetLogMsg("开始监听数据");
      this.run = true;
      console.log("this.buyNum = %s, this.buyNumFix = %s", this.buyNum, this.buyNumFix)
      console.log("this.poolNum = %s, this.poolNumFix = %s", this.poolNum, this.poolNumFix)
      console.log("this.inMin = %s, this.inMinFix = %s", this.inMin, this.inMinFix)
      console.log("this.inMax = %s, this.inMaxFix = %s", this.inMax, this.inMaxFix)
      console.log("this.multiInMax = %s, this.multiInMaxFix = %s", this.multiInMax, this.multiInMaxFix)
      console.log("this.delayBlock = %s", this.delayBlock)

      let funType = 1;/// 1: 用XX代币购买 2：购买指定数量的代币。31：延迟用XX代币购买 32: 延迟购买指定数量的代币
      if (this.multiInMax > 0) {
        funType = 2;
      }

      if (this.delayBlock > 0 && !self.robModel) {
        funType = 31;
        if (this.multiInMax > 0) {
          funType = 32;
        }
      }
      let gasPrice = self.web3.utils.toWei(self.gasPrice+"", "gwei");
      let from = self.web3.eth.defaultAccount;
      self.nonce = await self.web3.eth.getTransactionCount(from);
      console.log(`from = ${from} self.nonce = ${self.nonce}`)
      self.timer = setInterval(() => {
        if (!self.run) {
          clearInterval(self.timer);
          self.timer = null;
          return;
        }
        if (!self.robModel) {
          self.checkPoolInfo().then((result)=>{
            console.log(result)
            if (!self.timer) return;
            let baseBN = new BigNumber(result.baseBalance);
            let quoteBN = new BigNumber(result.quoteBalance);

            if (baseBN.gt(self.poolNumFix) && quoteBN.gt("0")) {
              self.insertSuccessLogMsg(`满足池子${self.baseTokenConfig.tokenSymbol}要求：${self.poolNum}`)
              clearInterval(self.timer);
              self.timer = null;
              self.splitMethods(funType, gasPrice, from);
            } else {
              self.insertNormalLogMsg(`${self.baseTokenConfig.tokenSymbol}池子：${baseBN.div(result.baseFixDecimals).toFixed(2)}`)
              self.insertNormalLogMsg(`${self.quoteTokenConfig.tokenSymbol}池子：${quoteBN.div(result.quoteFixDecimals).toFixed(2)}`)
            }
          });
        } else {
          self.splitMethods(funType, gasPrice, from);
        }

      }, this.oneTime);
    },
    async splitMethods(funType, gasPrice, from) {
      let self = this;
      if (funType > 30) {
        self.insertNormalLogMsg(`延迟${self.delayBlock}秒购买`)
        setTimeout(() => {
          self.splitMethods(funType-30, gasPrice, from);
        }, self.delayBlock*1000);
        return;
      }
      if (funType === 1) {
        if (self.autoRetry) {
          if (self.autoTimer) {
            clearInterval(self.autoTimer);
            self.autoTimer = null;
          }
          self.autoTimer = setInterval(()=> {
            self.quickSwap(
                self.router,
                self.tokenRecipient,
                self.tokenSender,
                self.tokenRecipient,
                gasPrice,
                from,
                true
            ).then((status)=>{
              if (status && self.autoTimer) {
                clearInterval(self.autoTimer);
                self.autoTimer = null;
                self.quickSwap(
                    self.router,
                    self.tokenRecipient,
                    self.tokenSender,
                    self.tokenRecipient,
                    gasPrice,
                    from,
                    false
                );
              }
            });
          }, self.oneTime);
          return;
        }
        await self.quickSwap(
            self.router,
            self.tokenRecipient,
            self.tokenSender,
            self.tokenRecipient,
            gasPrice,
            from,
            false
        );
      } else {
        if (self.autoRetry) {
          if (self.autoTimer) {
            clearInterval(self.autoTimer);
            self.autoTimer = null;
          }
          self.autoTimer = setInterval(()=> {
            self.quickMultiSwapForAmountInMaxs(
                self.router,
                self.tokenRecipient,
                self.tokenSender,
                self.tokenRecipient,
                gasPrice,
                from,
                true
            ).then((status)=>{
              if (status && self.autoTimer) {
                clearInterval(self.autoTimer);
                self.autoTimer = null;
                self.quickMultiSwapForAmountInMaxs(
                    self.router,
                    self.tokenRecipient,
                    self.tokenSender,
                    self.tokenRecipient,
                    gasPrice,
                    from,
                    false
                );
              }
            });
          }, self.oneTime);
          return;
        }
        await self.quickMultiSwapForAmountInMaxs(
            self.router,
            self.tokenRecipient,
            self.tokenSender,
            self.tokenRecipient,
            gasPrice,
            from,
            false
        );
      }
    },
    async withdraw(index) {
      console.log("withdraw")
      let self = this;
      self.insertNormalLogMsg("开始提取")
      let token = self.baseTokenConfig.token;
      if (index != 1) {
        token = self.quoteTokenConfig.token;
      }
      console.log(token)
      if (!self.quickContract) {
        self.initContract()
      }
      let recs = this.recipient.split("\n");
      let rec = recs[0];
      if (rec.length == 0) {
        self.insertErrorLogMsg("钱包接收地址有误，请检查")
        return;
      }
      try {
        let result = await self.quickContract.methods.transferForeignToken(token, rec).send({
          from: self.web3.eth.defaultAccount,
          gasPrice: self.web3.utils.toWei("6.001", "gwei"),
          gasLimit: 1000000,
        }).once("transactionHash", (tx) => {
          self.insertSuccessLogMsg("已提交提取,hash:"+tx)
        });
        if (result.status == true) {
          console.log("提取成功")
          self.$message.success("提取成功")
          self.insertSuccessLogMsg("提取成功")
        } else {
          console.log("提取失败")
          self.$message.error("提取失败")
          self.insertErrorLogMsg("提取失败")
        }
      } catch (e) {
        console.log(e)
        console.log("提取失败"+e.err)
        self.insertErrorLogMsg("提取失败"+e.message.split("(")[0])
      }
    },
    async deposit(index) {
      let self = this;
      let token = index==1 ? self.baseTokenConfig.token : self.quoteTokenConfig.token;
      if (index == 1 && self.baseTokenConfig.approved > 0) {
        return;
      } else if (self.quoteTokenConfig.approved > 0) {
        return;
      }
      console.log(token)
    },
    async getAmountsOut() {
      let self = this;
      if (self.routerContract||!self.quickContract) {
        self.initContract()
      }
      if (!self.web3) {
        return;
      }
      if (!self.tokenSender || !self.tokenRecipient) return;
      let router = self.router;
      try {
        if (self.sellIsWallet) {
          let sellGetMax = await self.quickContract.methods.getAmountsOut(
              self.quoteTokenConfig.balance,
              self.baseTokenConfig.token,
              self.quoteTokenConfig.token,
              router
          ).call({
            from: self.web3.eth.defaultAccount
          });
          let getMax = new BigNumber(sellGetMax).div(self.quoteTokenConfig.fixDecimals).toFixed(4);
          self.sellGetMax = getMax;
          return;
        }
        let sellGetMax = self.quickContract.methods.getAmountsOut(
            0,
            self.baseTokenConfig.token,
            self.quoteTokenConfig.token,
            router
        ).call({
          from: self.web3.eth.defaultAccount
        });
        let getMax = new BigNumber(sellGetMax).div(self.quoteTokenConfig.fixDecimals).toFixed(4);
        self.sellGetMax = getMax;
      } catch (e) {
        console.log(e)
        console.log("查询卖出金额失败")
      }
    },
    async checkTokenInfos() {
      let checkState = true;
      let self = this;
      while (checkState) {
        self.checkTokenInfo(1, false).then();
        self.checkTokenInfo(2, false).then();
        self.getAmountsOut().then();
        await self.sleep(3000);
      }
    },
    async checkPoolInfo() {
      let self = this;
      try {
        let web3 = self.web3;
        if (!self.address) {
          await self.privateToAccount();
        }
        let router = self.routerConfigItem.id != 0 ? self.routerConfigItem.id : self.diyRouter;
        self.router = router;

        if (!self.routerContract || !self.quickContract) {
          self.initContract();
        }
        if (!self.web3) {
          return;
        }
        let tokenContract = self.quickContract;
       let result = await tokenContract.methods.poolInfo(router, self.tokenSender, self.tokenRecipient).call({
         from: web3.eth.defaultAccount
       });
       return result;
      } catch (e) {
        self.insertErrorLogMsg("池子监测失败"+e.message.split("(")[0])
      }
    },
    getNowSeconds() {
      return Math.floor(Date.now()/1000);
    },
    async sell(sellType) {
      // self.web3.utils.toWei("5.01", "gwei"),

      let self = this;

      if (!self.tokenSender) {
        self.insertErrorLogMsg("请输入发送token地址")
        return
      }
      if (!self.tokenRecipient) {
        self.insertErrorLogMsg("请输入获得token地址")
        return
      }
      self.insertNormalLogMsg("开始卖出")
      if (self.routerContract || !self.quickContract) {
        self.initContract()
      }
      let router = self.router;

      let recs = this.recipient.split("\n");
      let rec = recs[0];
      if (rec.length == 0) {
        self.insertErrorLogMsg("钱包接收地址有误，请检查")
        return;
      }
      self.insertNormalLogMsg("准备卖出")
      if (self.sellIsWallet) {
        try {

          let balance = new BigNumber(self.quoteTokenConfig.balance).div(sellType).toFixed(0)
          console.log(`balance = ${balance}`)
          let result = await self.routerContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
              balance,
              0,
              [self.quoteTokenConfig.token, self.baseTokenConfig.token],
              rec,
              self.getNowSeconds() + 10
          ).send({
            from: self.web3.eth.defaultAccount,
            gasPrice: self.web3.utils.toWei(self.gasPrice, "gwei"),
            gasLimit: self.gasLimit,
          }).once("transactionHash", (tx) => {
            self.insertSuccessLogMsg("卖出已提交：" + tx)
          });
          if (result.status == true) {
            self.$message.success("卖出成功")
            self.insertSuccessLogMsg("卖出成功")
          } else {
            self.insertErrorLogMsg("卖出失败")
          }
        } catch (e) {
          self.insertErrorLogMsg("卖出失败"+e.message.split("(")[0])
        }
        return;
      }
      try {
        // let sellType = 0;/// 1 -> all; 2: 1/2; 3: 1/3

        let result = await self.quickContract.methods.quickSwapSell(
            router,
            self.tokenSender,
            self.tokenRecipient,
            sellType,
            self.slippage,//滑点
            rec
        ).send({
          from: self.web3.eth.defaultAccount,
          gasPrice: self.web3.utils.toWei(self.gasPrice, "gwei"),
          gasLimit: 2000000,
        }).once("transactionHash", (tx) => {
          self.insertSuccessLogMsg("卖出已提交：" + tx)
        });
        if (result.status == true) {
          self.$message.success("卖出成功")
          self.insertSuccessLogMsg("卖出成功")
        }
      } catch (e) {
        self.$message.error(e)
        self.insertErrorLogMsg("卖出失败"+e.message.split("(")[0])
      }
    },
    sleep(t) {
      return new Promise(e=>setTimeout(e, t))
    },
    insertLogMsg(msg, colorType) {
      msg = `${new Date().toUTCString()}: ${msg}`
      switch (colorType) {
        case 1:
          this.logMsg = "<div style='color: #909399; '>"+msg+"</div>"+this.logMsg;
          break
        case 2:
          this.logMsg = "<div style='color: #F56C6C; '>"+msg+"</div>"+this.logMsg;
          break
        case 3:
          this.logMsg = "<div style='color: #67C23A; '>"+msg+"</div>"+this.logMsg;
          break
        default:
          this.logMsg = "<div style='color: #909399; '>"+msg+"</div>"+this.logMsg;
          break
      }
    },
    insertNormalLogMsg(msg) {
      this.insertLogMsg(msg, 1)
    },
    insertErrorLogMsg(msg) {
      this.insertLogMsg(msg, 2)
    },
    insertSuccessLogMsg(msg) {
      this.insertLogMsg(msg, 3)
    },
    resetLogMsg(msg) {
      msg = `${new Date().toUTCString()}: ${msg}`
      this.logMsg = "<div style='color: #909399;'>"+msg+"</div>"
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  width: 50%;
  flex: 1;
  max-width: 1000px;
}

.config-item {
  margin-right: 10px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
